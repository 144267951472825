import React from 'react';
import Image from 'next/image';
import styles from './banner.module.css';

const Banner = () => {
    return (
        <div className={styles.mainContainer}>
            <div className={styles.backgroundImage} />
            <div className={styles.container}>
                <div id="flex-container">
                    <div className={styles.title}>
                        Soulbound tokens for Pakistani flood victims
                    </div>
                    <div className={styles.cards}>
                        <div
                            className={`${styles.imgContainer} ${styles.hideCard}`}
                        >
                            <Image
                                src={'/images/banner-card-1.png'}
                                width={274}
                                height={373}
                            />
                        </div>
                        <div
                            className={styles.imgContainer}
                            style={{ top: 45 }}
                        >
                            <Image
                                src={'/images/banner-card-2.png'}
                                width={274}
                                height={373}
                            />
                        </div>
                        <div className={styles.imgContainer}>
                            <Image
                                src={'/images/banner-card-3.png'}
                                width={274}
                                height={373}
                            />
                        </div>
                    </div>
                    <div
                        className={`${styles.bottomBannerText} ${styles.hollowText}`}
                    >
                        soulbound•soulbound
                    </div>
                </div>
                <div className={styles.discordContainer}>
                    <h5>CRYPTO FOR CAUSES</h5>
                    <div className={styles.discordDesc}>
                        Mint soulbound tokens and support Pakistani flood
                        victims, the world’s largest population of climate
                        refugees. Proceeds go to accredited organizations making
                        a sustainable difference on the ground.
                    </div>
                    <a target="_blank" href="https://discord.gg/mMSbhSjt59">
                        <button className={styles.button}>JOIN DISCORD</button>
                    </a>
                </div>
            </div>
            <div className={`${styles.hollowText} ${styles.sbt}`}>SBT</div>
        </div>
    );
};

export default Banner;
