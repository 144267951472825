import React from 'react';
import Head from 'next/head';
import { NextPage, GetStaticProps } from 'next';
import Layout from '../components/layout';
import LandingPageInfo from '../components/landingPage';
import { TrendingAPI } from '../services/trending';
import { INft, IProfile } from '../interfaces';
import { Session } from 'next-auth';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { AppLoader } from '../components/loader';
import Banner from '../components/banner';

interface IHomePage {
    onFire: INft;
    dontMissOut: INft;
    creators: IProfile;
    session: Session;
}

const Home: NextPage<IHomePage> = ({ onFire, dontMissOut, creators }) => {
    const APP_BASE_URL = process.env.NEXT_PUBLIC_APP_BASE_URL;
    const FB_APP_ID = process.env.NEXT_PUBLIC_FB_APP_ID;
    const topBannerDesc = 'Your complete Metaverse toolkit';

    const { data: session, status: sessionStatus } = useSession();

    if (session) {
        useRouter().replace('/home');
    }

    return (
        <>
            <Layout>
                <Head>
                    <title>Superfandom - {topBannerDesc}</title>
                    <meta property="og:url" content={`${APP_BASE_URL}`} />
                    <meta property="og:type" content="website" />
                    <meta property="fb:app_id" content={FB_APP_ID} />
                    <meta property="og:title" content={'Super Fandom'} />
                    <meta name="twitter:card" content="summary" />
                    <meta property="og:description" content={topBannerDesc} />
                    {/* <meta property="og:image" itemProp="image" content={null} /> */}
                </Head>
                <Banner />

                {sessionStatus == 'loading' && (
                    <AppLoader text="Please wait..." />
                )}
                {sessionStatus == 'authenticated' && (
                    <AppLoader text="Loading data..." />
                )}
                {sessionStatus == 'unauthenticated' && (
                    <LandingPageInfo
                        topBannerDesc={topBannerDesc}
                        onFire={onFire}
                        dontMissOut={dontMissOut}
                        creators={creators}
                        session={undefined}
                    />
                )}
            </Layout>
        </>
    );
};

export default Home;

export const getStaticProps: GetStaticProps = async () => {
    const onFire: any = await TrendingAPI.nfts({
        featured: true,
        limit: 7,
    });
    const creators: IProfile = await TrendingAPI.creators(100);

    return {
        props: {
            onFire,
            dontMissOut: [],
            creators,
        },

        revalidate: 60 * 60, // 1 hour
    };
};
